@media screen and (max-width: 480px) {
    .contact_depath {
        width: 100% !important;
        }
        .row.map_contact_set {
            width: 100% !important;
        }
        .section-header h2 {
            font-size: 30px !important;
        }
        .contact_depath-info-icon.fir {
            width: 50px !important;
        }
    section.main_section_web {
        overflow: hidden ;
        background: #f7fbff ;
    }
    /* header css Satrt  */
    .page-header {
        position: fixed;
        z-index: 99;
        width: 100%;
        height: 50px;
    }
    /* header css End  */
    .page-header .panel-wrapper {
        min-width: 0px;
        margin: 0px auto;
        box-sizing: border-box;
        padding: 0px 12px;
    }
    .page-header .panel-wrapper .panel-header .logo {
        margin: -23px 0px 0px 0px;
        padding: 0px 10px;
    }
    .page-header .panel-wrapper .panel-header .logo img {
        width: 134px;
        height: 33px;
        padding-top: 0px;
    }
    .page-header .panel-wrapper .panel-header .uPres a {
        display: none;
    }
    .logged {
        display: none;
    }
    .page-header .panel-wrapper .panel-header .mini-cart .text {
        color: #ffffff;
        display: none;
        padding: 0px;
        font-family: sans-serif;
        padding-left: 1px;
        letter-spacing: 1px;
        margin-top: 2px;
    }
    .page-header .panel-wrapper .panel-header .uPres {
        margin: 0px 8px;
        max-width: 212px;
        padding: 0px;
    }
    .nav-sections .navigation {
        background: 0 0;
        display: none;
        margin-left: auto;
        margin-right: auto;
        min-width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    ::-webkit-scrollbar-thumb {
        background: transparent !important;
    }
    .page-header .panel-wrapper .panel-header .mini-cart {
        max-width: 0px;
        margin: 0px 0px 0px 0px;
        position: relative;
    }
    .page-header .panel-wrapper .panel-header .search-bar {
        min-width: 304px;
        display: none;
        width: calc(100% - 586px);
    }
    .open>.dropdown-menu {
        display: block;
        width: 302px !important;
    }
    span#delivery_txt {
        font-size: 12px;
    }
    .nav_bar-detail-icons {
        display: flex;
        position: absolute;
        right: 17px;
        top: -13px !important;
    }
    .notification-media {
        display: block;
        float: left;
        /* padding-left: 217px; */
    }
    .notification-media i {
        color: white;
        font-size: 21px;
        position: relative;
    }
    span.offer-count {
        position: absolute;
        background: #b8f074;
        height: 15px;
        width: 15px;
        line-height: 15px;
        border-radius: 100px;
        margin-left: -12px;
        color: white;
        top: 26px;
        padding-left: 3.5px;
    }
    
    .cart-media {
        display: block;
        padding-left: 21px;
    }
    i.fa-solid.fa-cart-shopping.m-res {
        font-size: 20px;
        color: white;
        position: relative;
    }
    span.offer-count-cart {
        position: absolute;
        background: #b8f074;
        height: 15px;
        width: 15px;
        line-height: 15px;
        border-radius: 100px;
        margin-left: -12px;
        color: white;
        top: 24px;
        padding-left: 4px;
    }
    .dropdown-menu {
        position: absolute !important;
        top: 40px !important;
        z-index: 17 !important;
    }
    .search-bar.m-veiw {
        display: block !important;
        border: 1px solid transparent;
        padding: 12px 20px;
        margin-bottom: 20px !important;
        background: white;
        margin-top: 79px;
        height: 48px;
        border-radius: 6px;
    }
    input#search {
        outline-width: 0 !important;
        border: none;
        width: 100%;
    }
    .nav-sections {
        margin-bottom: 16px;
        background: #ece7e7;
        display: none;
    }
    .block-search {
        display: flex;
    }
        .page-header .panel-wrapper .panel-header .search-bar .block-search {
            width: 415px;
            border: 1px solid;
        }
    .product-menu {
        background: #4a8ee6;
        display: none;
    }
    .dropdown.pin-code-div {
        padding: 0px 0px 0px 0px;
        font-size: 13px;
    }

    .media-responsive-div {
        background: #4a8ee6;
        width: 100% !important;
    }
    /* Responsive Product List Css Start  */
    .responsive-product-list {
        padding: 0px 20px;
        text-align: center;
        display: block;
        background-color: #f3f7fb;
        padding-bottom: 13px;
    }
    .cat-list {
        margin-bottom: 10px;
    }
    .product-list-img {
        width: 100%;
        height: 65px;
        background: #fff;
        margin-top: 10px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
    span.major-content {
        color: #0b1219;
        font-size: 12px;
    }
/* Responsive Product List Css End  */
   


/* Responsive trending today slider css Start  */
.carousels {
    max-width: 1239px !important;
    margin: 0px auto;
    /* margin-left: 23px; */
    padding-top: 0px;
    position: relative;
}
.home.Trending {
    width: 100%;
}

  .carousel-items img {
    width: 100%;
    height: 100px !IMPORTANT;
}
.cat-product-section h2 {
    padding: 0px 0px 0px;
    font-size: 15px;
}
.heading-cat-product p {
   padding: 0px 2px;
    padding-top: 2px;
}
.cat-product-section .two {
    display: none;
}

/* Responsive trending today slider css End  */

.carousel-item-product.slick-slide.slick-cloned {
    /* width: 243px !important; */
    overflow: hidden;
}

/* order-meds-prev div Css Start  */
.acr-health-store {
    display: block !important;
    padding-bottom: 20px;
}
.order-meds-prev {
    width: 48%;
    height: 72px;
    float: left;
    margin-right: 0px;
    border-radius: 8px;
    box-shadow: 0 6px 12px -4px rgb(11 18 25 / 10%);
    background: #fff;
    /* display: flex; */
    /* align-items: center; */
}
.Netmeds_Stores {
    margin: 13px 16px;
    display: block;
    color: #151b39;
    font-size: 14px;
    font-family: sans-serif;
    font-weight: 600;
    width: 100%;
}
.f-msg.lg_green-text {
    width: auto;
    background: url(../images/keyboard_arrow_right_disabled.svg) no-repeat 125px  !important;
    font-size: 12px;
    line-height: 93px;
    letter-spacing: 0;
    padding-left: 15px;
    font-weight: 600;
    color: #4a8ee6;
}
.contactdh {
    justify-content: space-between;
    display: flex;
    padding: 0px 0px;
}
/* order-meds-prev div Css End  */
.medicine-purches {
    margin-bottom: 10px;
}

/* home-static-new css Start  */

.home-static-new {
    display: block !important;
}
h2.Shop.by.Category {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 0px 30px;
}
.Category-card {
    width: 58%;
}
.category-section_for-product_s {
    width: 100%;
    overflow: hidden;
    margin: 0 5px 12px 0;
    padding: 8px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 6px 12px -4px rgb(40 11 18 / 10%);
}
.shopby_icategory {
    display: flex;
    justify-content: space-around;
}
span.product_act_img {
    display: flex;
    /* max-height: 100px; */
    /* min-height: 100px; */
    align-items: center;
    justify-content: center;
}
span.product_act_img img {
    max-height: 100px;
}
.product_cat-name {
    font-size: 12px;
    color: #000;
    letter-spacing: 0;
    display: block;
    text-align: center;
    margin: 0 auto;
    overflow: hidden;
    height: 36px;
    line-height: 18px;
}
/* home-static-new css End  */


button.slick-next.slick-arrow {
    display: none !important;
}
button.slick-prev.slick-arrow {
    display: none !important;
}
/* New.On.Netmeds.--swiper-slide css Start  */
/* .New.On.Netmeds.--swiper-slide-- {
    display: block;
}
.swiper-container {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
  }
  .swiper-3d {
    perspective: 19335px !important;
}
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 151px;
    padding: 0px 40px;
} */
/* New.On.Netmeds.--swiper-slide Css End  */

/* Popular Tests  Packages Section Css Start */
.test_pack{
    display: block;
}
.on-slider-section__ {
    margin: 16px 0 0;
    padding: 0 0 16px;
    display: flex;
    overflow-x: scroll !important;
    /* overflow: hidden  !important; */
}

.swipper_slider.ripper_effect {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px!important;
    margin-right: 12px!important;
    box-shadow: 0 6px 12px -4px rgb(11 18 25 / 10%);
}
.swipper_slider.ripper_effect img {
    border-radius: 5px;
    width: 100%;
}
element.style {
    overflow: hidden;
    padding: 0px 4px;
}
.test_pack {
    display: block;
    padding-bottom: 26px;
    padding-top: 0px;
}
.image_of.populer-product {
    display: flex;
    overflow: scroll;
    padding-top: 10px;
}
.img__1 {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px!important;
    margin-right: 12px!important;
    box-shadow: 0 6px 12px -4px rgb(11 18 25 / 10%);
}
.img__1 img {
    border-radius: 5px;
    width: 110px;
}

/* Popular Tests  Packages Section End  */



/* footer top css Start  */
.footer-top {
    min-width: 100%;
}
.copyblock {
    min-width: 100%;
}
/* footer top css End  */
.Shop_Category_heading h2 {
    font-size: 14px;
    font-weight: 700;
    padding: 0px 0px;
}
.Shop_Category_heading p {
    text-align: end;
    padding: 0px 0px;
}

.scrool h2 {
    font-size: 15px;
    font-weight: 600;
    padding: 0px 16px;
}
.scrool h2 a {
    font-weight: 100;
    right: 1px;
    position: absolute;
    padding: 0px 15px;
}
.home.Trending h2 {
    font-size: 20px;
    text-align: center;
}



section#fMembers {
    border-top: 1px solid rgba(11,18,25,.1);
    padding: 24px 0;
    display: block;
}
.fmember-content {
    display: flex;
}
img.lazyautosizes.lazyloaded {
    /* vertical-align: middle; */
    border-style: none;
    width: 100%;
    padding: 140px;
    display: inline-block;
    position: absolute;
    right: 152px;
}
.fmember-rightcontent {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
}
.top {
    padding-bottom: 12px;
    text-align: left!important;
}
.top h2 {
    font-size: 16px;
    color: #0b7e81;
    line-height: 22px;
    margin: 0;
}
.con-txt {
    display: block;
}
.con-txt ul {
    margin-left: 16px;
}
.con-txt ul li {
    font-size: 12px;
    line-height: 18px;
    color: rgba(11,18,25,.75);
    margin-bottom: 8px;
}

span.machines-trit {
    display: flex;
    max-height: 100px;
    min-height: 100px;
    align-items: center;
    justify-content: center;
}
.product-box_with_i {
    width: 100%;
    overflow: hidden;
    margin: 0 5px 12px 0;
    padding: 8px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 6px 12px -4px rgb(40 11 18 / 10%);
}
span.machines-trit img {
    max-height: 100px;
}
span.produc_cat_name- {
    font-size: 12px;
    color: #000;
    letter-spacing: 0;
    display: block;
    text-align: center;
    margin: 0 auto;
    overflow: hidden;
    height: 36px;
    line-height: 18px;
}
.New_shop_by_catergry {
    display: block;
}



/* archeath first membership  css Start  */
section#fMembers_con_txt {
    border-top: 1px solid rgba(11,18,25,.1);
    padding: 24px 20px;
    margin: auto;
    background: #f3f7fb;
    justify-content: center;
    display: block;
}
.lazyautosizes_lazyloaded {
    padding-top: 28px;
}
.lazyautosizes_lazyloaded img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
}
.con-text_top {
    padding-bottom: 12px;
    text-align: left!important;
}
.con-text_top h2 {
    font-size: 19px;
    font-family: sans-serif;
    color: #4a8ee6;
    line-height: 22px;
    margin: 0;
    padding-left: 25px;
    font-weight: 500;
}
.f-msg {
    float: left;
    width: auto;
    background: url(../images/flesh.png) no-repeat left center;
    background-size: 24px !important;
    padding-left: 32px;
    font-size: 12px;
    color: #4a8ee6;
    line-height: 24px;
    letter-spacing: 0px;
    height: 24px;
}
a.explore-plans.btn-ripple {
    float: right;
    width: auto;
    padding: 0px 5px;
    font-size: 11px;
    color: #ffffff;
    letter-spacing: 1px;
    background: #4a8ee6;
    border-radius: 4px;
    height: 34px;
    line-height: 33px;
    box-shadow: 0px 6px 12px 0px rgb(65 191 70 / 40%);
}
.bottom {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid rgba(11, 18, 25, 0.1);
    padding-bottom: 15px;
}

/* responsive section Start archealth Get medicine refill every month */
div#section_113{
    display: block !important;
}
div#section_113 {
    padding: 16px 17px;
    border-bottom: 1px solid rgba(11, 18, 25, 0.1);
    background: #f3f7fb;
}
.lSection {
    float: left;
    width: calc(100% - 110px);
}
.lSection h2 {
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}
.lSection ul {
    margin: 0px 0px 0px 0px;
}
.lSection ul li {
    font-size: 12px;
    line-height: 18px;
    color: rgba(11, 18, 25, 0.75);
    margin-bottom: 8px;
}
a.view_btn.btn-ripple {
    display: block;
    height: 32px;
    font-size: 12px;
    line-height: 32px;
    width: 100px;
    color: white;
    background: #4a8ee6;
    border-radius: 4px;
    box-shadow:  0px 6px 12px 0px rgb(65 191 70 / 40%);
    text-align: center;
    letter-spacing: 1px;
    margin-top: 16px;
}

.rSection {
    float: right;
    width: 110px;
    height: 120px;
    padding-left: 16px;
    padding-top: 15px;
}
.rSection img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
}
 /* responsive section End archealth Get medicine refill every month */
 .Grab.Our.TOP_Picks {
    display: flex;
    justify-content: center;
    background: #f1f6fa;
    padding-left: 45px !important;
    padding: 0px 29px;
    padding-bottom: 59px;
}
.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    padding: 0px 36px !important;
}
span.acehealthcare_Stores {
    font-size: 11px !important;
    font-weight: 700;
    padding-top: 18px;
    position: absolute;
    padding-left: 16px;
}
button.accordion.st {
    background-color: #fff;
    color: #444;
    cursor: pointer;
    border-radius: 8px;
    padding: 1.0rem;
    border: none;
    margin-top: 10px;
    text-align: left;
    width: 364px !important;
    font-size: 15px;
    transition: 0.4s;
}
.question h2 {
    font-size: 14px;
    color: #4a8ee6;
    cursor: pointer;
    position: relative;
    padding-bottom: 12px;
    padding-left: 18px;
    margin: 0;
    line-height: 16px;
}
.panel b {
    font-weight: 400;
    padding-bottom: 10px;
}
.panel h1 {
    font-size: 14px;
    font-weight: 700;
}
.panel a {
    color: #4a8ee6;
}


/* footer menu bar css Start  */
.mobile-bottom-bar {
    width: 100%;
    height: 52px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    box-shadow: 0 -1px 1px rgba(41,0,0,.2);
    z-index: 9;
}
.mobile-bottom-bar .footer-link.active {
    color: #151b39;
}
.mobile-bottom-bar .footer-link {
    flex: 1 1 auto;
    text-align: center;
    /* padding: 7px .5rem; */
}
.btn-ripple {
    position: relative;
    overflow: hidden;
    display: inline-block;
    transition: all .2s ease-in-out;
}
.btn-ripple:before {
    border-radius: 50%;
    background-color: rgba(50,174,177,.8);
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
}
.mobile-bottom-bar .footer-link .fhome-icon {
    width: 24px;
    height: 24px;
    display: flex;
    margin: 0 auto;
    background-size: 24px!important;
}
.mobile-bottom-bar .footer-link.active .fhome-icon {
    background: url(../images/act-logo.png) no-repeat center;
}
.mobile-bottom-bar .footer-link .footer-text {
    position: relative;
}
.footer-text {
    width: 49%;
    padding: 0;
    font-size: 14px;
    line-height: 24px;
    font-family: sans-serif;
    color: rgba(11,18,25,.5);
    display: flex;
    margin: auto;
}
.mobile-bottom-bar a span {
    color: #4a8ee6;
}
a#fHome span {
    color: #707074;
}

.mobile-bottom-bar .footer-link .forder-icon {
    background: url(../images/order.svg) no-repeat center;
    width: 24px;
    height: 24px;
    display: flex;
    margin: 0 auto;
}
.mobile-bottom-bar .footer-link .fbeauty-icon {
    background: url(../images/beauty.svg) no-repeat center;
    width: 24px;
    height: 24px;
    display: flex;
    margin: 0 auto;
}
.mobile-bottom-bar .footer-link .fwellness-icon {
    background: url(../images/wellness.svg) no-repeat center;
    width: 24px;
    height: 24px;
    display: flex;
    margin: 0 auto;
}
.mobile-bottom-bar .footer-link .fpro-icon {
    background: url(../images/profile.svg) no-repeat center;
    width: 24px;
    height: 24px;
    display: flex;
    margin: 0 auto;
}


section.Chooseness_ACE.Healthcare {
    display: none;
}
footer {
    display: none !important;
}
small.copyright {
    display: none;
}

/* cart */
.shopping-cart {
    display: flex;
    flex-direction:column;
    /* align-items: center; */
    justify-content: center;
}

}