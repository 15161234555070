* {
    margin: 0px;
    padding: 0px;
}

body {
    margin: 0;
    font-family: sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #0b1219;
    text-align: left;
    background-color: #f3f3f3;
}

a {
    text-decoration: none !important;
}

/* header css start  */
.page-header {
    padding-bottom: 9px;
    margin: 0px 0px 0px !important;
    border-bottom: 0px solid#eee !important;
}

.page-header {
    background: #4a8ee6;
    position: relative;
    padding: 0px 10px;
}

.page-header .panel-wrapper {
    max-width: 1370px;
    margin: 0px auto;
    box-sizing: border-box;
    padding: 0px 5px;
}

.page-header .panel-wrapper .panel-header {
    display: flex;
    height: 90px;
    line-height: 90px;
}

.page-header .panel-wrapper .panel-header .logo {
    max-width: unset;
    margin: -10px 33px;
}

.page-header .panel-wrapper .panel-header .logo img {
    width: 78%;
    padding-top: 15px;
    margin-left: -14px;
}

button.carousel-control-prev {
    background: #4a8ee6;
    width: 35px;
    height: 35px;
    margin: auto;
    border-radius: 100px;
    border: 1px solid #eee;
}

button.carousel-control-next {
    background: #4a8ee6;
    width: 35px;
    height: 35px;
    margin: auto;
    border-radius: 100px;
    border: 1px solid #eee;
}

footer {
    background-image: linear-gradient(to bottom, rgba(36, 174, 177, 0) 13%, rgb(143 215 54) 195%), linear-gradient(to bottom, #fff, #fff);
}

/* search bar csss start  */
.search_border{
    border: 1px solid black;
    background-color: #fff;
    padding: 7px;
    margin-bottom: 10px;
}
.page-header .panel-wrapper .panel-header .search-bar {
    max-width: calc(100% - 586px);
    align-items: center;
    display: flex;
}

input#search {
    outline-width: 0;

}

.page-header .panel-wrapper .panel-header .search-bar {
    min-width: 304px;
    width: calc(100% - 738px);
}

.page-header .panel-wrapper .panel-header .search-bar .block-search {
    height: 48px;
    position: relative;
    background: #ffffff;
    border-radius: 6px;
    line-height: normal;
}

.page-header .panel-wrapper .panel-header .search-bar .block-search {
    width: 76%;
}

/* pin code css start  */
.page-header .panel-wrapper .panel-header .search-bar .block-search .pinCode {
    position: relative;
    width: 160px;
    height: auto;
    margin: 13px 0;
    display: inline-block;
    padding: 0px 0 0px 16px;
    font-family: sans-serif;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    color: rgba(11, 18, 25, 0.5);
    float: left;
}

.page-header .panel-wrapper .panel-header .search-bar .block-search .pinCode span {
    display: inline-block;
    line-height: 22px;
}

.page-header .panel-wrapper .panel-header .search-bar .block-search .pinCode .pinNum {
    font-family: LatoBold;
    font-size: 14px;
    font-stretch: normal;
    letter-spacing: normal;
    color: #24aeb1;
    border: 0px;
    width: 74px;
    cursor: pointer;
}


/* <new> css satrt  */
.dropdown-menu>li>a {
    display: block;
    padding: 0px 3px !important;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

div#saved_location {
    padding: 16px 0;
    margin: 0 16px;
    border-bottom: 1px solid rgba(0, 0, 0, .08);
}

div#saved_location h1.title {
    margin: 0;
    padding: 0 0 12px;
    font-size: 16px;
    line-height: 16px;
    color: #000;
    float: left;
    font-weight: 600;
    width: 100%;
    display: block;
}

span#delivery_txt {
    font-size: 14px;
    color: rgba(11, 18, 25, .5);
    line-height: 16px;
}

button.signin_btn {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 1px;
    background: #4a8ee6;
    color: #fff;
    border-radius: 4px;
    font-weight: 600;
    width: 100%;
    border: 0;
    margin-top: 16px;
    text-align: center;
    padding: 12px 0;
}

i.fa-solid.fa-location-crosshairs {
    font-size: 17px;
    padding-top: 2px;
}

div#delivery_details h1 {
    margin: 0;
    padding: 0 0 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #151b39;
}

div#delivery_details span {
    font-size: 14px;
    color: rgba(0, 0, 0, .5);
    line-height: 20px;
}

.pCode_form {
    margin: 16px 0 0;
}

div#rel_pincode_form {
    position: relative;
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    color: #262626;
    text-decoration: none;
    background-color: white !important;
}

.pin_detail {
    margin: 0;
    padding: 8px 8px 8px 36px;
    border-radius: 6px;
    clear: both;
    position: relative;
    background: #f3f7fb;
}

.pin_detail input#rel_pincode {
    border: 0;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    color: #151b39;
    float: left;
    width: 200px;
    outline: 0;
    padding: 0;
    background: 0 0;
}

.detect_location {
    margin-top: 13px;
    color: #ef4281;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 5px;
    padding-left: 3px;
    background-size: 16px;
    cursor: pointer;
    font-weight: 600;
}

i.fa-solid.fa-location-dot {
    padding-top: 10px;
    position: absolute;
    z-index: 99;
    font-size: 21px;
    padding-left: 10px;
    color: #7a8092;
}

.dropdown-toggle::after {
    display: none !important;
}

div#saved_addresses {
    color: transparent;
}

/* delivery css start  */
.delivery_popup {
    position: absolute;
    display: block;
    z-index: 101;
    top: 52px;
    left: 0;
    width: 360px;
}

.overlay_bg {
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    background: #000;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 0;
}

.delivery_popup .pinoce_content {
    display: block;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 6px 12px -4px rgba(11, 18, 25, .2);
    position: relative;
    z-index: 1;
}

.delivery_popup .pinoce_content #saved_location {
    padding: 16px 0;
    margin: 0 16px;
    border-bottom: 1px solid rgba(0, 0, 0, .08);
}

.delivery_popup .pinoce_content #saved_location h1.title {
    margin: 0;
    padding: 0 0 12px;
    font-size: 16px;
    font-family: LatoBold;
    line-height: 16px;
    color: #000;
    float: left;
    width: 100%;
    display: block;
}

.clearfix {
    height: 0px;
    clear: both;
    display: flex;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.delivery_popup .pinoce_content #saved_location span {
    font-size: 14px;
    font-family: Lato;
    color: rgba(11, 18, 25, .5);
    line-height: 16px;
}

.delivery_popup .pinoce_content #saved_location .signin_btn {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    font-family: sans-serif;
    background: #24aeb1;
    color: #fff;
    border-radius: 4px;
    width: 100%;
    border: 0;
    margin-top: 16px;
    text-align: center;
    padding: 12px 0;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button;
}

.delivery_popup .pinoce_content #saved_location .saved_location_swiper {
    margin-top: 16px;
    margin-right: -16px;
    padding: 0;
}

.swiper-container {
    margin-left: auto;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}

.delivery_popup .pinoce_content #delivery_details {
    padding: 16px;
    position: relative;
}

.delivery_popup .pinoce_content #delivery_details h1 {
    margin: 0;
    padding: 0 0 6px;
    font-size: 16px;
    font-family: LatoBold;
    line-height: 20px;
    color: #151b39;
}

.delivery_popup .pinoce_content #delivery_details span {
    font-size: 14px;
    font-family: Lato;
    color: rgba(0, 0, 0, .5);
    line-height: 20px;
}

.delivery_popup .pinoce_content #delivery_details .pCode_form {
    margin: 16px 0 0;
}

.delivery_popup .pinoce_content #delivery_details .pCode_form #rel_pincode_form {
    position: relative;
}

.delivery_popup .pinoce_content #delivery_details .pCode_form #rel_pincode_form .pin_detail .inp_text {
    border: 0;
    height: 24px;
    line-height: 24px;
    font-family: sans-serif;
    font-size: 14px;
    color: #151b39;
    float: left;
    width: 200px;
    outline: 0;
    padding: 0;
    background: 0 0;
}

.delivery_popup .pinoce_content #delivery_details #rel_pin_msg {
    width: 100%;
    clear: both;
    text-align: left;
    font-size: 11px;
    padding-top: 0;
    position: relative;
    margin: 6px 0 0;
    color: #5ba41c;
    font-style: italic;
    font-family: sans-serif;
}



/* auto Search css start  */
.page-header .panel-wrapper .panel-header .search-bar .block-search .auto-search {
    width: calc(100% - 160px);
    height: 35px;
    float: left;
    position: relative;
    display: block;
    overflow: hidden;
}

.page-header .panel-wrapper .panel-header .search-bar .block-search .auto-search .search-section {
    display: inline-block;
    margin: 0;
    float: left;
    width: 100%;
}

.page-header .panel-wrapper .panel-header .search-bar .block-search .auto-search .search-section .input-text {
    margin: 0px;
    padding: 0px 64px 0px 12px;
    height: 48px;
    border: 0px;
    width: 100%;
    background: transparent;
    font-size: 14px;
}

.aa-dropdown-menu {
    background: #fff;
    padding: 10px;
    width: 100%;
    top: 50px !important;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .2);
}


/* upload_prescription_button css start  */
a.mini_banner_partercode i.fa-solid.fa-cloud-arrow-up {
    font-size: 16px;
}

.page-header .panel-wrapper .panel-header .uPres {
    margin: 0 15px;
    padding: 0px;
}

.page-header .panel-wrapper .panel-header .uPres a {
    font-family: sans-serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #ffffff;
    background-size: 24px;
    padding-left: unset;
    height: 90px;
    display: inline-block;
}

/* mini-cart css start  */
.page-header .panel-wrapper .panel-header .mini-cart {
    max-width: 70px;
    margin: 0px 0px 0px 0px;
    position: relative;
}

.page-header .panel-wrapper .panel-header .mini-cart .text {
    color: #ffffff;
    padding: 0px;
    font-family: sans-serif;
    padding-left: 1px;
    letter-spacing: 1px;
    margin-top: 2px;
}

i.fa-solid.fa-cart-shopping {
    font-size: 16px;
}

.page-header .panel-wrapper .panel-header .mini-cart .text .counter-number {
    background: #ee4380 !important;
    border-radius: 100px;
    color: #ffffff !important;
    display: block;
    font-family: LatoBold;
    font-size: 10px;
    height: 10px;
    line-height: 10.7px;
    padding: 0px;
    position: absolute;
    left: 9px;
    text-align: center;
    text-indent: 0px;
    top: 37px;
    width: 13px;
    letter-spacing: 0px;
}

/* minicart_container css start  */
.minicart_container {
    display: none;
}

.minicart_container .mc_triangle {
    border-color: transparent transparent #fff transparent;
    border-style: solid;
    border-width: 0 20px 20px 20px;
    height: 0;
    width: 0;
    position: absolute;
    top: 62px;
    right: -8px;
}

.minicart_container .mc_content {
    display: block;
    position: absolute;
    background: #fff;
    padding: 16px;
    border-radius: 5px;
    top: 72px;
    right: -60px;
    width: 320px;
    box-shadow: 0 2px 5px 0 rgba(21, 27, 57, .2);
}

/* login css start  */
i.fa-solid.fa-user {
    background: white;
    color: #4a8ee6;
    height: 20px;
    width: 20px;
    font-size: 13px;
    line-height: 20px;
    border-radius: 100px;
    text-align: center;
}

.page-header .panel-wrapper .panel-header .login {
    margin: 0px;
    max-width: 160px;
}

/* .page-header .panel-wrapper .panel-header .login  {
    font-family: sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
    background-size: 24px;
    padding-left: 32px;
    height: 90px;
    display: inline-block;
} */

.page-header .panel-wrapper .panel-header .login a#logged_user {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}

/* Product menu css start  */
.product-menu {
    background: #4a8ee6;
}
.bg-body-grn{
    background-color:#4a8ee6 ;
}

/* button.dropbtn span {
    font-size: 170px;
} */

.dropbtn {
    background-color: transparent;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown.medicin {
    margin-top: -23px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background: #f7f7f7;
    /* min-width: 400px; */
    box-shadow: 0 5px 10px -2px rgb(0 0 0 / 20%);
    z-index: 2;
    top: 60px;
    padding: 0;
    height: auto;
    left: 100px;
}

button.dropbtn {
    height: 83px;
    width: 280px;
    font-family: sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 33px;
    letter-spacing: normal;
    color: rgba(255, 255, 255, .8);
}

.dropdown-content a {
    color: #151b39;
    text-decoration: none;
    font-size: 13px;
    height: 38px;
    line-height: 40px;
    display: block;
    width: 100%;
    padding: 0 12px;
}



.dropdown-content a:hover {
    background-color: #fff;
    color: #4a8ee6;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: transparent;
}


i.fa-solid.fa-prescription-bottle-medical {
    font-size: 18px;
    background: white;
    color: #ec506b;
    width: 23%;
    height: 27px;
    text-align: center;
    line-height: 28px;
    border-radius: 100px;
}

i.fa-solid.fa-spa {
    font-size: 18px;
    background: white;
    color: #ec506b;
    width: 23%;
    height: 27px;
    text-align: center;
    line-height: 28px;
    border-radius: 100px;
}

i.fa-solid.fa-vial {
    font-size: 18px;
    background: white;
    color: #ec506b;
    width: 11%;
    height: 29px;
    text-align: center;
    line-height: 28px;
    border-radius: 100px;
    margin-top: 3px;
}

i.fa-solid.fa-spray-can-sparkles {
    font-size: 18px;
    background: white;
    color: #ec506b;
    width: 24%;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 100px;
}

i.fa-solid.fa-caret-down {
    font-size: 10px;
    display: inline-block;
}

i.fa-solid.fa-caret-down:hover {
    rotate: 180deg;
}

.product-menu .col-md-3 {
    text-align: center;
}

.dropdown-content a {
    text-align: left;
}

.product-menu-div span {
    font-size: 17px;
    color: #e9f7d7;
    font-weight: 400;
}

/* nav-sections product itmes SHow Css Start */
.nav-sections {
    padding-bottom: 20px;
    background: #fff;
    padding-top: 25px;
}

.nav-sections .navigation {
    background: 0 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1350px;
    /* padding-top: 19px; */
    padding-bottom: 5px;
}

.nav-sections .navigation .ui-menu {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: space-between;
}

.nav-sections .navigation .ui-menu li {
    box-sizing: border-box;
    color: #000;
    display: inline-block;
    font-family: Lato;
    font-size: 13px;
    font-weight: normal;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    font-family: sans-serif;
}

.nav-sections .navigation .ui-menu li .level-top {
    box-sizing: border-box;
    color: #000;
    line-height: 1px;
    padding: 10px;
    font-size: 14px;
    position: relative;
    text-decoration: none;
    text-transform: capitalize;
    font-weight: 600;
}


/* new :start */
.dropdown.pin-code-div {
    position: relative;
    height: auto;
    display: inline-block;
    /* padding: 0px 2px 5px 16px; */
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: rgba(11, 18, 25, 0.5);
    /* float: left; */
    border-right: 1px solid rgba(11, 18, 25, 0.2);
}

div#accordionExample {
    margin-top: -6px;
    margin-right: 4px;
    margin-left: -19px;
    --bs-accordion-border-color: none !important;
    --bs-accordion-btn-padding-x: 0.1rem;
}

.btn-default {
    color: #333;
    background-color: transparent !important;
    background-image: none;
    border-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: transparent;
    background-color: transparent !important;
}

.accordion-button:focus {
    border-color: transparent !important;
    box-shadow: none !important;
}


/* socus css satr  */


.product .itemBox {
    border-radius: 4px;
    height: 241px;
    cursor: pointer;
    padding: 3px 18px;
}

.card {
    height: 241px;
    width: 209px !important;
}

.card img {
    margin: auto;
    margin-top: 23px;
    width: 51% !important;
}

.card-body {
    text-align: center;
}

h4.card-title {
    font-size: 15px;
}

p.card-text {
    font-size: 12px;
}

.notification-media {
    display: none;
}

.cart-media {
    display: none;
}

.search-bar.m-veiw {
    display: none !important;
}

span.pin_code {
    color: #4a8ee6;
    font-size: 14px;
    font-weight: 600;
}

/* ShoppingCart.css */
.shopping-cart {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
}

.cart {
    width: 50%;
    margin-bottom: 20px;
}

.cart-item {
    display: flex;
    margin-bottom: 10px;
    border: 1px solid rgba(195, 195, 195, .5);
    padding: 10px;
    margin: 10px;
}

.cart-item img {
    width: 100px;
    height: 100px;
    margin-right: 20px;
}

.item-details {
    display: flex;
    flex-direction: column;
}

.rate {
    font-size: 12px;
    color: #000;
}

.packing {
    font-size: 15px;
}

.batchExpirydiv {
    margin-left: auto;
    position: relative;
}

.batchExpirydiv span {
    font-size: 12px;
    padding-right: 10px;
    color: #000;
}

.Selectquantity {
    margin-top: 10px;
}

.Dropdown_input_Box{
    width: 55px ;
    height: 28px;
    border: 1px solid black;
    display: flex;
    flex-direction: row;   
    align-items: center;
    justify-content: center;
    /* border-radius: 5px; */
}

.order-summary {
    width: 30%;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    height: 250px;
}

.order-summary h2 {
    margin-bottom: 10px;
}
.order-summary div{
    display: flex;
    flex-direction: row ;
    justify-content: space-between;
}
.total {
    border-top:1px dashed #e0e0e0;
    /* line-break: ; */
    margin-top: 10px;
}

.place-order-button {
    padding: 10px 20px;
    background-color: #4a8ee6;
    color: #fff;
    border: none;
    cursor: pointer;
}

.place-order-button:hover {
    background-color: #9de743;
}

.batchExpirydiv .place-order-button {
    padding: 4px 8px;
    background-color:red;
    color: #fff;
    border: none;
    cursor: pointer;
}

.batchExpirydiv.place-order-button:hover {
    background-color: #9de743;
}


/* Responsive Product List Css Start  */
.responsive-product-list {
    display: none;
}

/* Responsive Product List Css End  */



.media-responsive-div {
    display: none;
}

/* slider css start  */
.top-slider-section {
    overflow: hidden;
    padding: 12px 16px;
    background-color: #f3f7fb;
    margin: auto;
    display: flex;
    justify-content: center;
}

.carousel-indicators {
    position: absolute;
    bottom: -10px !important;
    left: 50%;
    z-index: 15;
    width: 60%;
    padding-left: 0;
    margin-left: -30%;
    text-align: center;
    list-style: none;
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 9px !important;
    height: 9px !important;
    border-radius: 100% !important;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #FFF !important;
    opacity: .5;
    transition: opacity .6s ease;
}

.carousel-indicators .active {
    width: 9px !important;
    height: 9px !important;
    border-radius: 100% !important;
    margin: 0;
    background-color: #4a8ee6 !important;
}

.carousel-item img {
    width: 100% !important;
    overflow: hidden !important;
}


/* new_catagory sbilabhide Css Start  */
.new_catagory__sbilabhide {
    background: #f3f7fb;
}

.new_catagory.sbilabhide {
    background: #f3f7fb;
    margin: auto;
    max-width: 1370px;
}

.cat-list {
    float: left;
    width: 100%;
    margin: 0 24px 0 0;
    background: #fff;
    border-radius: 8px;
    display: flex;
    padding: 12px 16px;
    box-shadow: 0 6px 12px -4px rgb(11 18 25 / 10%);
    position: relative;
}

span.car-imgs img {
    width: 56px;
    height: 62px;
}

span.cat-contain-txt {
    display: block;
    text-align-last: start;
    padding: 7px 0px 0px 55px;
}

span.heading {
    display: block;
    font-size: 20px;
    font-weight: 600;
}

span.save_text {
    font-size: 14px;
    font-weight: 600;
    color: #4a8ee6;
}


/* new slider pen code  css start */
.slide-container {
    max-width: 1120px;
    width: 100%;
    padding: 40px 0;
}

.slide-content {
    margin: 0 40px;
    overflow: hidden;
    border-radius: 25px;
}

.card {
    border-radius: 25px;
    background-color: #FFF;
}

.image-content,
.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 14px;
}

.image-content {
    position: relative;
    row-gap: 5px;
    padding: 25px 0;
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #4070F4;
    border-radius: 25px 25px 0 25px;
}

.overlay::before,
.overlay::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -40px;
    height: 40px;
    width: 40px;
    background-color: #4070F4;
}

.overlay::after {
    border-radius: 0 25px 0 0;
    background-color: #FFF;
}

.card-image {
    position: relative;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background: #FFF;
    padding: 3px;
}

.card-image .card-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #4070F4;
}

.name {
    font-size: 18px;
    font-weight: 500;
    color: #333;
}

.description {
    font-size: 14px;
    color: #707070;
    text-align: center;
}

.button {
    border: none;
    font-size: 16px;
    color: #FFF;
    padding: 8px 16px;
    background-color: #4070F4;
    border-radius: 6px;
    margin: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.button:hover {
    background: #265DF2;
}

.swiper-navBtn {
    color: #6E93f7;
    transition: color 0.3s ease;
}

.swiper-navBtn:hover {
    color: #4070F4;
}

.swiper-navBtn::before,
.swiper-navBtn::after {
    font-size: 35px;
}

.swiper-button-next {
    right: 0;
}

.swiper-button-prev {
    left: 0;
}

.swiper-pagination-bullet {
    background-color: #6E93f7;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    background-color: #4070F4;
}

@media screen and (max-width: 768px) {
    .media-responsive-div {
        display: block !important;
    }

    .slide-content {
        margin: 0 10px;
    }

    .swiper-navBtn {
        display: none;
    }
}

/* Trending Today Slider Css Start  */
.medial-silder {
    background: #f3f7fb;
    margin: auto;
    padding: 4px 0px 20px 0px;
}

.carousels {
    max-width: 1239px;
    margin: 0px auto;
    /* margin-left: 23px; */
    padding-top: 0px;
    position: relative;
}

.carousel-items {
    margin-left: 10px;
    ;
}

.carousel-items img {
    width: 100%;
    border-radius: 10px;
}

.slick-prev,
.slick-next {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    text-align: center;
    background: white;
    border: 1px solid #fff;
    z-index: 1;
    border-radius: 100px;
    color: black;

}

.slick-next {
    right: 0px;
}

.home.Trending h2 {
    font-size: 22px;
    margin: 0px 0px 13px;
    color: #0b1219;
    font-weight: 600;
    padding-top: 20px;
    padding-left: 17px;
}

.home.Trending {
    justify-content: center;
    margin: auto;
    max-width: 1370px;
}

.xmtrr {
    width: 1370px;
    overflow: hidden;
    margin: auto;
}

/* pendding div Css Start  */
.pendding {
    background: #f3f7fb;
    padding-top: 15px;
}

/* cat css start  */
.cat-product-section h2 {
    padding: 11px 0px 0px;
    margin: 0 0 0;
    color: #fff;
    font-weight: 600;
    font-size: 22px;
    margin-left: -21px;
}

/* .cat-product-section {
    overflow: hidden;
    background: linear-gradient(180deg,#4a8ee6 55%,#f3f7fb 30%);
}
.cat-product-section .two{
    background: linear-gradient(180deg,#4a8ee6 55%,#f3f7fb 30%);
} */
.carousel-product {
    position: relative !important;
    max-width: 1227px;
    margin: auto;
}

.carousel-item-product {
    margin-left: 30px;
}

.carousel-item-product img {
    width: 100%;
}

.slick-prev,
.slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    text-align: center;
    background: transparent;
    border: 1px solid dimgray;
    z-index: 1;

}

.slick-next {
    right: 0px;
}

.card_beauty-top-products-image {
    display: flex;
    height: 202px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.card_beauty-top-products-image img {
    max-width: 100%;
    padding: 37px;
}

.cat-product-titel {
    line-height: inherit;
    padding: 0px 22px;
    font-size: 13px;
    color: #0b1219;
    font-weight: 500;
    min-height: auto;
    font-family: sans-serif;
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 10px;
}

span.price_box span {
    font-size: 14px;
    line-height: 20px;
    font-family: sans-serif;
    color: #0b1219;
    font-weight: 700;
}

span.price_box strike {
    font-size: 10px;
    line-height: 21px;
    color: #b9b9b9;
    font-family: sans-serif;
}

span.price_box {
    display: flex;
    padding: 0px 22px;
    gap: 10px;
}

.card_beauty-top-products {
    background: white;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 20px;
}

span.price-box-offer {
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    font-family: sans-serif;
    display: block;
    padding: 0 22px;
    font-weight: 600;
    color: #5ba41c;
}

.owl-carousel.BestDeals.owl-theme.owl-loaded.owl-drag {
    margin: 0px 0px 0px 273px;
}

.owl-carousel.Best_Deals.owl-theme.owl-loaded.owl-drag {
    margin: 0px 0px 0px 273px;
}

.express_delivery {
    height: 24px;
    display: block;
    float: left;
    width: 100%;
    margin-bottom: 0px;
}

.express_delivery .express_content {
    float: left;
    height: 24px;
    width: auto;
    font-size: 13px;
    color: #ef4281;
    padding: 2px 16px 0 32px;
    border-radius: 40px;
    background: url(../images/flash.svg) #ffe7f0 10px center no-repeat;
    background-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.limited-deal .beauty-top-products .swiper-slide .pro_detail .cart_btn {
    text-align: center;
    padding: 10px 0 0;
}

.cart_btn {
    padding: 0px 10px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
}

button.toCart.cartbag.addtocartbtn.prodbtn {
    background: #4a8ee6;
    width: 70%;
    height: 40px;
    font-size: 14px;
    border-radius: 4px;
    padding: 11px 0 7px;
    border: 0;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: auto;
}

button.slick-prev.slick-arrow {
    left: 23px;
}

.card_beauty-top-products a {
    text-decoration: none;
}

/* js Timer Css Start */

.offerjs-time {
    padding-left: 22px;
    padding-top: 8px;
    color: white;
    margin-bottom: -14px;
    font-size: 14px;
}

.offerjs-time i {
    color: #d3efef;
}

/* js Timer Css End */

/* heading in cat details  */
.heading-cat-product p {
    color: white;
    text-align: end;
    /* padding: 0px 27px; */
    padding-top: 24px;
    margin-bottom: 0px;
}

.heading-cat-product {
    padding-top: 24px !important;
    padding: 0px 40px;
}


/* Shop_Category_Css Start  */
.Category-card {
    padding: 0px 0px;
    text-align: center;
    width: 1370px;
    overflow: hidden;
}

.Shop_Category_heading {
    padding-bottom: 16px !important;
    width: 100% !important;
    padding: 31px 0px;
}

.Shop_Category_heading h2 {
    /* position: absolute; */
    /* bottom: 4px; */
    /* left: -34px; */
    color: #0b1219;
    font-weight: 600;
    font-size: 22px;
}

h2.acehealthcare.traks {
    padding-left: 21px;
}

.Shop_Category_heading p {
    text-align: end;
    padding: 0px 0px;
    color: #4a8ee6;
}

.custom_img {
    text-align: center;
    width: 100%;
}

.custom_img img {
    width: 100%;
}

.cat_title-cate span.Petcare-heading {
    text-align: center;
    display: block;
    font-size: 16px !important;
    color: #0b1219 !important;
    overflow: hidden;
    font-weight: 600;
    padding: 0px 10px;
    padding-bottom: 10px;
}

span.save {
    text-align: center;
    display: block;
    font-size: 14px;
    color: #5ba41c;
    margin-bottom: 20px;
}

div#carouselExampleCaptions {
    max-width: 1370px;
}

/* New On Netmeds Contain Css Start  */
h2.Netmeds {
    padding: 0px 0px 13px;
}

.home-welcome-txt {
    padding: 0px 0px;
}

.home-welcome-txt h1 {
    font-size: 19px;
    line-height: 24px;
    letter-spacing: normal;
    color: #0b1219;
}

.home-welcome-txt ul li {
    font-size: 14px;
    letter-spacing: normal;
    margin-bottom: 3px;
    margin-left: 15px;
    color: rgba(11, 18, 25, .75);
}

.home-welcome-txt p {
    font-size: 14px;
    letter-spacing: normal;
    margin-bottom: 8px;
    color: rgba(11, 18, 25, .75);
}


/* footer Css Start  */
footer {
    background: white;
    display: block !important;
    padding-top: 22px;
    margin-top: 51px;
}

.footer-top {
    display: flex;
    min-width: 1030px;
    max-width: 1280px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 4px 41px 28px;
    margin-bottom: 13px;
    border-bottom: 1px solid rgba(11, 18, 25, .2);
}

.footer-logo img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
}

.footer-logo {
    margin-right: 32px;
    padding-right: 32px !important;
    border-right: 0 solid rgba(195, 195, 195, .5);
    height: 62px;
    line-height: 62px;
    width: 212px;
}

.footer-text {
    width: 60%;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    font-family: sans-serif;
    color: rgba(11, 18, 25, .5);
    display: flex;
    align-items: center;
}


/* footer container  Css Start */
.footer-veiw {
    padding: 17px 0px;
    display: flex;
    justify-content: center;
}

.footer-veiw h2 {
    color: black;
    font-size: 14px;
    line-height: 22px;
    padding: 0;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-veiw ul {
    color: black;
    margin: 0;
    padding: 0;
}

.footer-veiw ul li {
    color: black;
    /* text-align: center; */
    list-style: none;
    line-height: 30px;
}

.footer-veiw .subscribe .store-icon {
    width: 46%;
    display: block;
    float: left;
    margin: 16px 16px 0 0;
}

.app-store {
    display: flex;
}

.footer-veiw .subscribe .store-icon img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
}

.footer-veiw .subscribe h2 {
    font-size: 14px;
    line-height: 22px;
    padding: 0;
    text-transform: uppercase;
}

.footer-veiw .subscribe {
    width: 284px;
    padding-left: 32px;
    font-size: 14px;
}

.footer-veiw ul li a {
    line-height: 32px;
    font-size: 14px;
    color: #0b1219;
}

.footer-veiw .subscribe .control input {
    width: 207px;
    height: 40px;
    border-radius: 4px;
    border: 0;
    padding: 0 12px;
}

.footer-veiw .subscribe .control {
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px solid rgba(11, 18, 25, .2);
}

.footer-veiw .subscribe .control .go-btn {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    color: #4a8ee6;
    border: 0;
    padding: 0;
    background: none;
    position: relative;
}

.footer-veiw .subscribe .control input {
    background-color: transparent;
    border: 0px solid;
}

textarea:focus,
input:focus {
    outline: none;
}

section.Best.Deals.on-slider-section {
    overflow: hidden;
    background: url(../images/vicks_Code.png) no-repeat left bottom / 240px, linear-gradient(to bottom, rgb(255 255 255 / -2%), rgba(255, 255, 255, .4) 78%), linear-gradient(180deg, #0D287B, #0D287B);
}

section.Best.Deals.on-slider-section.xmtr {
    overflow: hidden;
    background: url(../images/Money_Image.png) no-repeat left bottom / 240px, linear-gradient(to bottom, rgb(255 255 255 / -2%), rgba(255, 255, 255, .4) 78%), linear-gradient(180deg, #24aeb1, #24aeb1);
}



/* copy right  */
.copyright {
    background: #4a8ee6;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    text-align: left;
    width: 100%;
    height: 65px;
    padding: 0px 41px;
    margin-bottom: 2px;
}

.copyblock {
    min-width: 1030px;
    max-width: 1280px;
    padding: 0;
    box-sizing: border-box;
    margin: 0 auto;
    line-height: 65px;
    display: flex;
    justify-content: center;
    border-top: 1px solid rgba(195, 195, 195, .5);
}

.copyblock a {
    color: #fff;
}

.copyblock a:hover {
    color: #fff;
}


.acr-health-store {
    display: none;
}

.home-static-new {
    display: none !important;
}

.New.On.Netmeds.--swiper-slide-- {
    display: none;
}

.test_pack {
    display: none;
}

section.sho_p-catergory {
    display: flex;
    justify-content: center;
    padding: 0px 0px 31px 0px;
    background: #f3f7fb;
}

.New_shop_by_catergry {
    display: none;
}




/* shipping page css Start  */
.alway_home_li {
    display: flex;
    justify-content: center;
}

ul.return_back_home {
    display: flex;
    list-style: none;
    gap: 10px;
    padding-right: 853px;
    padding-top: 30px;
}

ul.return_back_home i {
    font-weight: 600;
    font-size: 11px;
    color: #151b39;
}

ul.return_back_home li {
    color: #4a8ee6;
    font-size: 14px;
    font-weight: 600;
}

ul.return_back_home li {
    color: #4a8ee6;
    font-size: 14px;
    font-weight: 600;
}

ul.return_back_home li a {
    color: #999;
    font-size: 14px;
    font-weight: 600;
    color: #151b39;
}

.stat-bnr {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    /* padding-left: 59px; */
}

.offset_heading_shep h3 {
    text-align: center;
    color: #4a8ee6;
    font-size: 28px;
    padding-top: 17px;
    font-weight: 500;
    word-spacing: 5px;
    /* padding-right: 200px; */
}

section.shipping_conditions.odd {
    padding-left: 0px;
}

section.shipping_conditions {
    max-width: 1125px;
    margin: auto;
    display: flex;
    justify-content: center;
    /* padding-left: 100px; */
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 43%;
    padding: 10px 10px 10px 10px;
}

tbody td {
    border: 1px solid #000;
    padding: 10px 0px 1px 8px;
    color: #7b808b;
}

.conditions p {
    padding-left: 7px;
}

.offset_heading_shep.CANCELLATION_POLICY h3 {
    padding-right: 0px;
    padding-left: 68px;
}

ul.return_back_home.cancell {
    padding-right: 792px;
}

ul.return_back_home.Terms {
    padding-right: 902px;
}

ul.return_back_home.Privacy {
    padding-right: 953px;
}

section.Chooseness_ACE.Healthcare {
    max-width: 1300px;
    margin: auto;
}

section#fMembers_con_txt {
    display: none;
}

div#section_113 {
    display: none;
}

.Grab.Our.TOP_Picks {
    display: none;
}

.mobile-bottom-bar {
    display: none;
}

.contact_depath_depath_depath {
    position: relative;
    width: 100%;
    padding: 45px 0;
}

.section-header {
    position: relative;
    width: 100%;
    max-width: 700px;
    margin: 0 auto 45px auto;
}

.section-header p {
    display: inline-block;
    margin-bottom: 10px;
    padding-bottom: 5px;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #4a8ee6;
}

.section-header h2 {
    margin: 0;
    font-size: 45px;
    font-weight: 700;
    text-transform: capitalize;
}

.contact_depath_depath .contact_depath_depath-info {
    width: 100%;
    margin-bottom: 45px;
    padding: 35px 30px 10px 30px;
    border-radius: 5px;
    background: #202C45;
}

.contact_depath .contact_depath-info h2 {
    color: #ffffff;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 30px;
}

.contact_depath .contact_depath-info-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
}

.contact_depath .contact_depath-info-icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 50px;
}

.contact_depath .contact_depath-info-icon i {
    margin: 0;
    color: #202C45;
    font-size: 16px;
}

.contact_depath .contact_depath-info-text {
    padding-left: 20px;
}

.contact_depath .contact_depath-info-text h3 {
    margin: 0 0 5px 0;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
}

.contact_depath .contact_depath-info-text p {
    margin: 0;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
}

.contact_depath .contact_depath-form {
    position: relative;
    width: 100%;
    margin-bottom: 45px;
}

.contact_depath .contact_depath-form input {
    padding: 21px 15px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #202C45;
    box-shadow: none;
    font-size: 16px;
}

.text-danger {
    color: #dc3545;
}

.contact_depath .contact_depath-form textarea {
    height: 150px;
    padding: 8px 15px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #202C45;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
    display: block !important;
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 10px;
    display: none !important;
}

button.owl-prev {
    width: 42px;
    height: 42px;
    background: #fff !important;
    border-radius: 100px !important;
    font-size: 20px !important;
    position: absolute;
    top: 38%;
    left: 0px;
}

button.owl-next {
    position: absolute;
    top: 38%;
    right: 0px;
    width: 42px;
    height: 42px;
    background: #fff !important;
    border-radius: 100px !important;
    font-size: 20px !important;
}

.owl-carousel.BestDeals.owl-theme.owl-loaded.owl-drag button.owl-prev {
    width: 42px;
    height: 42px;
    background: #fff !important;
    border-radius: 100px !important;
    font-size: 20px !important;
    position: absolute;
    top: 38%;
    left: 0px;
}

.owl-carousel.BestDeals.owl-theme.owl-loaded.owl-drag button.owl-prev:hover {
    width: 42px;
    height: 42px;
    background: #fff !important;
    border-radius: 100px !important;
    font-size: 20px !important;
    position: absolute;
    border: 1px solid #4a8ee6;
    color: #4a8ee6;
    top: 38%;
    left: 0px;
}

.owl-carousel.BestDeals.owl-theme.owl-loaded.owl-drag button.owl-next {
    width: 42px;
    height: 42px;
    background: #fff !important;
    border-radius: 100px !important;
    font-size: 20px !important;
    position: absolute;
    top: 38%;
    left: 76%;
}

.owl-carousel.BestDeals.owl-theme.owl-loaded.owl-drag button.owl-next:hover {
    width: 42px;
    height: 42px;
    background: #fff !important;
    border-radius: 100px !important;
    font-size: 20px !important;
    color: #4a8ee6;
    position: absolute;
    top: 38%;
    border: 1px solid #4a8ee6;
    left: 76%;
}

/* end  */
.owl-carousel.Best_Deals.owl-theme.owl-loaded.owl-drag button.owl-prev {
    width: 42px;
    height: 42px;
    background: #fff !important;
    border-radius: 100px !important;
    font-size: 20px !important;
    position: absolute;
    top: 38%;
    left: 0px;
}

.owl-carousel.Best_Deals.owl-theme.owl-loaded.owl-drag button.owl-prev:hover {
    width: 42px;
    height: 42px;
    background: #fff !important;
    border-radius: 100px !important;
    font-size: 20px !important;
    position: absolute;
    border: 1px solid #4a8ee6;
    color: #4a8ee6;
    top: 38%;
    left: 0px;
}

.owl-carousel.Best_Deals.owl-theme.owl-loaded.owl-drag button.owl-next {
    width: 42px;
    height: 42px;
    background: #fff !important;
    border-radius: 100px !important;
    font-size: 20px !important;
    position: absolute;
    top: 38%;
    left: 76%;
}

.owl-carousel.Best_Deals.owl-theme.owl-loaded.owl-drag button.owl-next:hover {
    width: 42px;
    height: 42px;
    background: #fff !important;
    border-radius: 100px !important;
    font-size: 20px !important;
    color: #4a8ee6;
    position: absolute;
    top: 38%;
    border: 1px solid #4a8ee6;
    left: 76%;

}

.owl-carousel.home_st.owl-theme.owl-loaded.owl-drag .item img {
    height: 250px;
}

.btn.btn-custom {
    padding: 10px 30px 12px 30px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    background: #4a8ee6;
    border: none;
    border-radius: 60px;
    box-shadow: inset 0 0 0 0 #000;
    -webkit-transition: ease-out 0.5s;
}

.btn.btn-custom:hover {
    color: #fff;
    background: #4a8ee6;
    border: 1px solid #000;
    box-shadow: inset 200px 0 0 0 #000;
}

.contact_depath iframe {
    width: 100%;
    height: 400px;
    border-radius: 5px;
}

.contact_depath-info {
    width: 100%;
    margin-bottom: 45px;
    padding: 5px 30px 10px 30px;
    border-radius: 5px;
    background: #4a8ee6;
}

.contact_depath-info-icon.fir {
    width: 65px;
}

ul.social-media-list {
    display: flex;
    justify-content: space-around;
}

ul.social-media-list li {
    width: 35px;
    height: 35px;
    border-radius: 100px;
    background: #fff;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}

a.contact-icon_deflot {
    color: #000;
    font-size: 16px;
}

.section-header p::after {
    position: absolute;
    content: "";
    width: 50%;
    height: 2px;
    left: 25%;
    bottom: 0;
    background: #4a8ee6;
}

.contact_depath {
    position: relative;
    width: 1400px;
    padding: 45px 0px 0px 0px;
    margin: auto;
}

.row.map_contact_set {
    width: 1400px;
    margin: auto;
}





/* news css add  */
.media-cont ul li>h4 {
    margin: 0 0 5px;
    font-size: 16px;
    color: #4a8ee6;
    font-family: sans-serif;
}

.media-cont ul li {
    list-style: none;
    border-bottom: 2px dotted #6f7284;
    padding-bottom: 15px;
    margin-bottom: 10px;
}

a.pressAdd {
    color: #151b39;
    margin-bottom: 5px;
    display: block;
}

div#digital p {
    color: #4a8ee6;
    margin-bottom: 12px !important;
}

a.pressAdd img {
    max-width: 14%;
    margin: 15px 0px;
}

/* news css add  */

/* faq css start  */
.faq-content h4 {
    color: #0b7e81;
    font-size: 16px;
    margin: 20px 0 15px;
    font-family: sans-serif;
}

.faq-menu1 h5 {
    color: red;
    position: relative;
    cursor: pointer;
    padding: 3px 5px;
    line-height: 18px;
    font-size: 14px;
    margin: 10px 0;
    letter-spacing: .25px;
}

.accordionlxmt .accordionlxmt-item {
    border-bottom: 1px dashed #e5e5e5;
}

.accordionlxmt .accordionlxmt-item button[aria-expanded=true] {
    border-bottom: 1px dashed #4a8ee6;
}

.accordionlxmt button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 1em 0;
    color: #4a8ee6;
    font-size: 1.15rem;
    font-weight: 400;
    border: none;
    background: none;
    outline: none;
}

.accordionlxmt button:hover,
.accordionlxmt button:focus {
    cursor: pointer;
    color: #4a8ee6;
}

.accordionlxmt button:hover::after,
.accordionlxmt button:focus::after {
    cursor: pointer;
    color: #4a8ee6;
    border: 1px solid #4a8ee6;
}

.accordionlxmt button .accordionlxmt-title {
    padding: 1em 1.5em 1em 0;
    font-size: 18px;
}

.accordionlxmt button .icon {
    display: inline-block;
    position: absolute;
    top: 18px;
    right: 0;
    width: 22px;
    height: 22px;
    border: 1px solid;
    border-radius: 22px;
}

.accordionlxmt button .icon::before {
    display: block;
    position: absolute;
    content: "";
    top: 9px;
    left: 5px;
    width: 10px;
    height: 2px;
    background: currentColor;
}

.accordionlxmt button .icon::after {
    display: block;
    position: absolute;
    content: "";
    top: 5px;
    left: 9px;
    width: 2px;
    height: 10px;
    background: currentColor;
}

.accordionlxmt button[aria-expanded=true] {
    color: #4a8ee6;
}

.accordionlxmt button[aria-expanded=true] .icon::after {
    width: 0;
}

.accordionlxmt button[aria-expanded=true]+.accordionlxmt-content {
    opacity: 1;
    max-height: 9em;
    transition: all 200ms linear;
    will-change: opacity, max-height;
}

.accordionlxmt .accordionlxmt-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
}

.accordionlxmt .accordionlxmt-content p {
    font-size: 15px;
    font-weight: 300;
    overflow-y: scroll;
    height: 110px;
    margin: 12px 1em 1.5em 0em;
}

.accordionlxmt .accordionlxmt-content p::-webkit-scrollbar {
    width: 9px;
}

.accordionlxmt .accordionlxmt-content p::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f1f1f1;
    border-radius: 8px;
    background: #f1f1f1;
}

.accordionlxmt .accordionlxmt-content p::-webkit-scrollbar-thumb {
    background: #4a8ee6;
    border-radius: 10px;
}

.accordionlxmt .accordionlxmt-content p::-webkit-scrollbar-thumb:hover {
    background: #a5e15c;
}

section.shipping_conditions.policy h4 {
    color: #4a8ee6;
    font-size: 19px;
    font-weight: bold;
}

/* faq css end  */

/* about us css start  */
span.abt-txt {
    font-size: 14px;
    font-family: sans-serif;
    color: #151b39;
    display: block;
    padding: 0 0 10px;
    font-weight: bold;
}

p.abt-cont {
    border-bottom: 1px dashed #c1c1c1;
    margin-bottom: 20px;
}

.about-col h1 {
    color: #4a8ee6;
    font-size: 20px;
    margin: 29px 0 8px;
    font-family: sans-serif;
    padding: 0;
    font-weight: 500;
}

.abt-images img {
    position: absolute;
    left: -44px;
    max-width: 4%;
}

/* about us css end  */


/* custom speak css start  */
.static-title.testi-title {
    text-align: center;
    padding: 10px 15px 15px;
}

.static-title.testi-title h3 {
    color: #4a8ee6;
    font-size: 24px;
    font-family: sans-serif;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.static-title.testi-title span {
    font-size: 13px;
    color: #4a8ee6;
}

.customer_service.media-cont.active ul li a {
    color: #151b39;
    margin-bottom: 5px;
    display: block;
}

.customer_service.media-cont.active ul li p {
    font-weight: bold;
}

.customer_service.media-cont.active ul li {
    list-style: none;
    border-bottom: 2px dotted #6f7284;
    padding-bottom: 15px;
}

section.main_section_web {
    background: #f7fbff !important;
}

button#read_more_testimonials {
    background: #4a8ee6;
    text-align: center;
    box-shadow: none;
    color: #fff;
    font-size: 14px;
    padding: 1px 12px;
    border-radius: 4px;
    font-family: sans-serif;
    display: block;
    text-transform: uppercase;
    border: 0;
    margin: 0 auto;
    line-height: 34px;
    height: 34px;
    outline: none;
}

/* custom speak css end  */


/* browser by A-Z css start  */
ul#demoFive,
li,
a {
    list-style-type: none;
    color: #222;
    text-decoration: none;
    /* display: flex; */
}

.listNav,
.ln-letters {
    overflow: hidden;
    display: flex;
    margin: auto;
}

.listNavHide {
    display: none
}

.listNavShow {
    list-style: none;
    padding: 0;
    margin-top: 10px;
}

.letterCountShow {
    display: block
}

.ln-letters a {
    width: 46px;
    color: #000;
    padding: 8px 0;
    text-align: center;
    background: #fff;
    font-family: LatoBold;
    display: flex;
    margin: auto;
    justify-content: center;
}

.ln-letters .ln-last {
    border-right: 1px solid silver
}

.ln-letters a:hover,
.ln-letters .ln-selected {
    background-color: #4a8ee6;
    color: #fff;
}

.ln-letters .ln-disabled {
    color: #ccc;
}

.ln-letter-count {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    text-align: center;
    font-size: 0.8em;
    line-height: 1.35;

}

.ln-letters a {
    border: 1px solid #eee;
    font-weight: 600;
}

ul.listNavShow li a {
    color: #000;
}

.innercontent {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 7px 0;
    width: 1110px;
    font-size: 14px;
}

.home_st .owl-next:hover {
    color: #4a8ee6 !important;
}

.home_st .owl-prev:hover {
    color: #4a8ee6 !important;
}

div#cont {
    width: 1100px;
    padding-top: 20px;
}

.innercontent h1 {
    margin: 3px 0px;
    color: #4a8ee6;
    font-size: 24px;
}

.innercontent span {
    font-style: italic;
    display: block;
    color: #4a8ee6;
}

h3.col_want {
    padding: 0px 0px 8px 0px;
    margin: 0;
}


/* popop css start  */
.backgroundOverlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background: transparent;
    opacity: 0.85;
    filter: alpha(opacity=85);
    -moz-opacity: 0.85;
    z-index: 101;
    display: none;
}

#btnClose_here {
    width: 100%;
    text-align: right;
    text-decoration: none;
    color: #BCBCBC;
}

/* use css  */
/*   This is the closing button  */
#btnClose_here {
    width: 100%;
    display: block;
    text-align: right;
    text-decoration: none;
    color: #000;
    opacity: .9;
}

/*   This is the closing button hover state  */
#btnClose_here:hover {
    color: #4a8ee6;
    opacity: 1.5;
}

.delayedPopup_hereWindow {
    display: none;
    position: fixed;
    width: auto;
    top: 27%;
    left: 1%;
    z-index: 102;
}

i.btn_pop_i {
    border-radius: 100%;
    position: absolute;
    left: 92%;
    top: -12px;
    font-size: 22px;
    color: #000;
    background: #fff;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
}

/* use css  */

/* popop css start  */


.conditions-block h4 {
    font-size: 14px;
    color: #151b39 !important;
    padding: 10px 0px;
}

.conditions-block {
    margin-bottom: 20px;
}

h4.text-blue {
    padding-top: 8px;
}

.conditions-block b {
    font-size: 15px;
    color: #151b39;
    font-weight: 400;
}

.conditions-block p b {
    font-weight: bold;
}

a.cmsbtn {
    background: #4a8ee6;
    color: #fff !important;
    display: inline-block;
    text-align: center;
    padding: 10px;
    margin: 0 0 10px;
    border-radius: 4px;
}

ul.bati3urw li {
    list-style: circle !important;
}

/* login screen css */
.Login-container{
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100vh;
}
.Login-top-div{
    width: 400px;
    text-align: center;
    /* background-color: #000; */
}
.Loginimg-container{
    height: 200px;
    width: 350px;
}
.Loginimg-container img{
    width: 100%;
    height: 100%;
}
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}
@media (max-width: 450px) {
  .h-custom {
  height: 100%;
  }
  .Top-img-container{
    display: none;
  }
}

/* commom header css */

.Common-header-heading {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
}

/* orders styles */
.order-top-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.accordion-body-backgroundcolor{
    background-color: #80808014;
    border-top: 0.3px solid gray;
}

/* payment screen style */

/* Custom Tab colors */
.custom-tab {
    background-color: #f8f9fa; /* Background color of the tabs */
    color: #495057; /* Text color of the tabs */
  }
  
  /* Custom Tab hover */
/* Custom Tab hover for Home tab */
.custom-tab .nav-link[data-rb-event-key="home"]:hover {
    background-color: red; /* Background color on hover */
    color: #495057; /* Text color on hover */
  }
  
  /* Active Tab */
  .custom-tab .nav-link.active {
    background-color: #ffffff; /* Background color of active tab */
    color: #000000; /* Text color of active tab */
  }


  /* product details screen style */

  .product-details-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .product-image-container {
    flex: 1;
    text-align: center;
  }
  
  .product-image {
    max-width: 100%;
    max-height: 300px;
  }
  
  .add-to-cart-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    width: 58%;
    margin-top: 17px;
  }
  
  .product-info-container {
    flex: 1;
    padding: 20px;
    background-color: #f0f0f066;
  }
  
  .product-info-container h2 {
    margin-top: 0;
  }
  
  @media (max-width: 768px) {
    .product-details-container {
      flex-direction: column;
    }
    .add-to-cart-button {
        position: absolute;
        width: 300px;
        bottom: 20px;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 16px;
        cursor: pointer;
      }
  }


  .cate-product-image {
    max-width: 100%;
    max-height: 200px;
  }